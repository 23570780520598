<template>
    <div>
        <v-main>
            <appbar class="mb-n3" v-if="!$route.meta.hideNavigation" />
            <transition name="router-anim" enter-active-class="animated zoomIn">
                <v-container fluid v-if="!$route.meta.hideNavigation">
                    <router-view :key="$route.path" />
                </v-container>
                <router-view :key="$route.path" v-else />
            </transition>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "Mainlayout",
    components: {
        appbar: () => import("./Appbar"),
    },
};
</script>
